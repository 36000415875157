<template>
    <div class="card">
        <Breadcrumb :model="items"/>
        <div class="p-m-3" style="font-size: 14px">
            <label style="font-size: 18px; font-weight: 600; color: #111111">会员企业信息</label>
            <div class="p-mt-2 p-grid p-ml-3">
                <div class="p-col-4">
                    <label>企业名称：{{from.companiesName}}</label>
                </div>
                <div class="p-col-6">
                    <label>信用代码：{{from.creditCode}}</label>
                </div>
            </div>
            <div class="p-mt-2 p-grid p-ml-3">
                <div class="p-col-4">
                    <label>企业类型：{{from.companiesType}}</label>
                </div>
                <div class="p-col-6">
                    <label>注册地址：{{from.enrollAddr}}</label>
                </div>
            </div>
            <div class="p-mt-2 p-grid p-ml-3">
                <div class="p-col-4">
                    <label>注册资本：{{from.registeredCapital}}</label>
                </div>
                <div class="p-col-6">
                    <label>法人代表：{{from.juridicalPerson}}</label>
                </div>
            </div>
            <div class="p-mt-2 p-grid p-ml-3">
                <div class="p-col-4">
                    <label>成立日期：{{new Date(from.establishTime).toLocaleDateString()}}</label>
                </div>
                <div class="p-col-6">
                    <label>所属行业：{{from.industry}}</label>
                </div>
            </div>
            <div class="p-mt-2 p-grid p-ml-3">
                <div class="p-col-12">
                    <label>经营范围：{{from.businessScope}}</label>
                </div>
            </div>
            <div style="display: flex; align-items: center" class="p-mt-2 p-grid p-ml-3">
                <div class="p-col-12">
                    <label>营业执照：</label>
                    <img :src="url+from.license">
                </div>
            </div>
          <div class="p-mt-2 p-grid p-ml-3">
            <div style="display: flex; align-items: center" class="p-col-12">
              <label>风采照片：</label>
              <img :src="url+from.pic">
            </div>
          </div>
        </div>
        <div class="p-m-2">
            <label style="font-size: 18px; font-weight: 600; color: #111111">法人信息</label>
            <div class="p-mt-2 p-grid p-ml-3">
                <div class="p-col-4">
                    <label>法人姓名：{{from.juridicalPersonName}}</label>
                </div>
                <div class="p-col-4">
                    <label>身份证：{{from.idCardNum}}</label>
                </div>
            </div>
            <div class="p-mt-2 p-grid p-ml-3">
                <div style="display: flex; align-items: center" class="p-col-4">
                    <label>身份证正面：</label>
                    <img :src="url+from.idCardFront">
                </div>
                <div style="display: flex; align-items: center" class="p-col-4">
                    <label>身份证反面：</label>
                    <img :src="url+from.idCardOpposite">
                </div>
            </div>
        </div>

        <div class="p-m-2 p-mt-4">
            <label style="font-size: 18px; font-weight: 600; color: #111111">开户信息</label>
            <div class="p-mt-2 p-grid p-ml-3">
                <div class="p-col-4">
                    <label>开户账号：{{from.accountId}}</label>
                </div>
                <div class="p-col-4">
                    <label> 联行号：{{from.unionPay}}</label>
                </div>
            </div>
            <div class="p-mt-2 p-grid p-ml-3">
                <div class="p-col-4">
                    <label>所属省份：{{from.province}}</label>
                </div>
                <div class="p-col-4">
                    <label> 所属市区：{{from.city}}</label>
                </div>
            </div>
            <div class="p-mt-2 p-grid p-ml-3">
                <div class="p-col-4">
                    <label>开户银行：{{from.bankName}}</label>
                </div>
                <div style="display: flex; align-items: center" class="p-col-4">
                    <label>开户许可证：</label>
                    <img :src="url+from.accountPermit">
                </div>
            </div>
        </div>
      <div class="p-p-2">
        <Button @click="$router.push('/certification')">修改企业信息</Button>
      </div>
    </div>
</template>

<script>
    export default {
        name: "",
        data() {
            return {
              url:this.$global_msg.imgurl,
                items: [
                    {label: '账户信息', to: '/companiesInformation'},
                    {label: '企业详情', to: '/companiesInformation'}
                ],
                from: {
                    companiesName:null,
                    /*信用代码*/
                    creditCode: null,
                    companiesType: null,
                    /*注册地址*/
                    enrollAddr: null,
                    /*注册资本*/
                    registeredCapital: null,
                    /*成立时间*/
                    establishTime: null,
                    /*法人*/
                    juridicalPerson: null,
                    /*经营范围*/
                    businessScope: null,
                    /*营业执照*/
                    license: null,

                    /*法人姓名*/
                    juridicalPersonName: null,
                    idCardNum: null,
                    /*身份证正面*/
                    idCardFront: null,
                    /*身份证背面*/
                    idCardOpposite: null,

                    /*开户许可*/
                    accountPermit: null,
                    accountId: null,
                    /*银联号*/
                    unionPay: null,
                    /*开户银行*/
                    bankName: null,
                    /*省份*/
                    province:null,
                    city: null,

                    permit: null,
                    pic: null,
                    brief: null,
                    industry: null,
                }
            }
        },
        mounted() {
            this.showCompaniesInformation()
        },
        methods: {
            showCompaniesInformation() {
              let ths= this
                this.$http.get('/company/showInformation').then((res) => {
                    console.log(res.data)
                  if(res.data==null){
                    ths.$router.push('/certification')
                  }
                   this.from = res.data
                })
            }
        }
    }
</script>

<style scoped>
img {
  width: 150px;
}

</style>